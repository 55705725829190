<template>
  <base-page
    class="page"
    title="Insights"
  >
    <template
      #aside
    >
      Sidebar
    </template>
    <template
      #default
    >
      <base-card>
        <base-title :level="1">
          My Insights
        </base-title>
        <base-paragraph>
          Welcome to the Insights page.
        </base-paragraph>
      </base-card>
    </template>
  </base-page>
</template>

<script>
export default {};
</script>
